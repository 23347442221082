<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom:20px;">基本信息</h3>
            <el-form
              ref="Form"
              :model="Form"
              :rules="rules"
              label-width="130px"
            >
              <el-form-item
                label="班级名称："
                prop="projectName"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectName"
                  placeholder="请输入班级名称"
                  :disabled="disabled"
                  maxlength="50"
                  show-word-limit
                  size="small"
                />
              </el-form-item>
              <el-form-item label="行政区划：" prop="areaId" class="form-item">
                <el-cascader
                  v-model="Form.areaId"
                  clearable
                  filterable
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  @change="areaChange"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="有效日期："
                prop="Effectivedate"
                class="form-item"
              >
                <el-date-picker
                  size="small"
                  v-model="Form.Effectivedate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="报名截止时间："
                prop="deadline"
                class="form-item"
              >
                <el-date-picker
                  size="small"
                  v-model="Form.deadline"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="负责人："
                prop="projectUser"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUser"
                  placeholder="请输入负责人"
                  :disabled="disabled"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="负责人电话："
                prop="projectUserPhone"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUserPhone"
                  placeholder="请输入负责人电话"
                  maxlength="12"
                  :disabled="disabled"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="培训类型："
                prop="Trainingtype"
                class="form-item"
              >
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="Form"
                  size="small"
                  @clearParams="clearParams"
                  modal
                  type
                  typeStu
                />
              </el-form-item>
              <el-form-item
                label="培训人数"
                prop="projectPeople"
                class="form-item"
                v-if="selectType != '0'"
                required
              >
                <el-input-number
                  v-model="Form.projectPeople"
                  @change="handleChange"
                  :min="1"
                  style="width:100%"
                  :disabled="disabled"
                  size="small"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="期次"
                prop="projectPc"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-input
                  type="text"
                  v-model="Form.projectPc"
                  :disabled="disabled"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="人群类型"
                prop="projectCrowd"
                class="form-item"
                v-if="selectType == '4'"
              >
                <el-select
                  v-model="Form.projectCrowd"
                  placeholder="请选择"
                  :disabled="disabled"
                  size="small"
                >
                  <el-option
                    v-for="item in CrowdtypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
           
            </el-form>
            <h3 style="padding-bottom:20px;">基本设置</h3>
            <div style="display:flex;flex-direction: column;">
              <div>
                <span style="padding:0 15px;width:13rem;text-align: right"
                  >允许学员查看学时证明</span
                >
                <el-radio-group v-model="Form.projectClassHourCertificate">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div style="padding:15px 0;" v-show="Form.auditState == '40'">
              <h3 style="padding-bottom:10px">审核事由</h3>
              <el-input
                type="textarea"
                disabled
                v-model="Form.auditReason"
              ></el-input>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "addClassCtr",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的负责人电话"));
      } else {
        callback();
      }
    };

    return {
      previewLoding1:false,
      disabled: false,
      paperId: false,
      areatreeList: [],
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: false,
        enableSurvey: false,
        auditState: "",
        auditReason: "",
        chatFileKeys: [],
        trainMethod: "",
        uploadfileList:[],
      },
      TrainingMethodsList: [],
      uploadFileList: [],
      Form1: {
        spotCheckNum: "",
      },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getCrowdtypeList();
    this.getareatree();
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const ProjectFileStyle = this.$setDictionary(
        "PROJECT_FILE_STYLE",
        "list"
      );
      const ProjectTrainMethod = this.$setDictionary(
        "PROJECT_TRAIN_METHOD",
        "list"
      );
      const list = [],
        ProjectFileStyleList = [],
        ProjectTrainMethodList = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      for (const key in ProjectFileStyle) {
        ProjectFileStyleList.push({
          value: key,
          label: ProjectFileStyle[key],
        });
      }
      for (const key in ProjectTrainMethod) {
        ProjectTrainMethodList.push({
          value: key,
          label: ProjectTrainMethod[key],
        });
      }
      this.CrowdtypeList = list;
      this.ProjectFileStyleList = ProjectFileStyleList;
      this.TrainingMethodsList = ProjectTrainMethodList;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.thumbnail = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
              this.doAjax();
        }
      });
    },
    // 添加
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName, //班级名称
        areaId: this.Form.areaId, // 行政区划Id
        startDate: this.Form.Effectivedate[0], //有效开始日期
        endDate: this.Form.Effectivedate[1], // 有效结束日期
        signEndDate: this.Form.deadline, //报名截止日期
        projectUser: this.Form.projectUser, //负责人
        projectUserPhone: this.Form.projectUserPhone, //负责人电话
        projectPeople: this.Form.projectPeople, //培训人数
        projectClassHourCertificate: this.Form.projectClassHourCertificate, //是否允许打印学时证明
        trainMethod: '10', //培训方式默认线上
      };
      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.$route.query.projectSource) {
        parmar.projectSource = this.$route.query.projectSource;
      }
      this.$post("/biz/project/hospital/save", parmar)
        .then((ret) => {
          const name = "classListBj";
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              name: name,
              params: {
                refresh: true,
                isAdd: true,
                addId: ret.data, //新班级Id，日报做处理
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
   
     reomveExlUpload(index) {
      this.Form.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less">
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }
}
</style>
